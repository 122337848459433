// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .referral-program-popup_dbdGb{display:flex;flex-direction:column;width:100%;margin:8px 0;background-color:#ffc13c;border-radius:5px}html.app__layout--desktop .referral-program-popup__bottom-container_MUjuu{overflow:hidden;transition:height .4s ease-out}html.app__layout--desktop .referral-program-popup--open_uotff html.app__layout--desktop .referral-program-popup__header-arrow_IKAE9{transition:.4s ease-out;transform:rotate(180deg)}html.app__layout--desktop .referral-program-popup--open_uotff html.app__layout--desktop .referral-program-popup__bottom-container_MUjuu{transition:height .6s ease-out}html.app__layout--desktop .referral-program-popup__body_RO8qi{display:flex;flex-direction:column;padding:0 16px 16px;border-top:1px solid #0000001a;flex-direction:row;gap:32px}html.app__layout--desktop .referral-program-popup__body-column_Vphmw{width:50%}html.app__layout--desktop .referral-program-popup__body-column_Vphmw{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-popup": `referral-program-popup_dbdGb`,
	"referral-program-popup__bottom-container": `referral-program-popup__bottom-container_MUjuu`,
	"referral-program-popup--open": `referral-program-popup--open_uotff`,
	"referral-program-popup__header-arrow": `referral-program-popup__header-arrow_IKAE9`,
	"referral-program-popup__body": `referral-program-popup__body_RO8qi`,
	"referral-program-popup__body-column": `referral-program-popup__body-column_Vphmw`
};
export default ___CSS_LOADER_EXPORT___;
