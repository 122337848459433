// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-reg_guGCi{width:100%;padding:12px 16px 64px;margin-top:60px}.referral-program-reg__terms_ZQSY0{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:flex;align-items:center;justify-content:center;width:100%;height:44px;margin:0 auto;color:var(--TextPrimary);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}@media(hover:hover)and (pointer:fine){.referral-program-reg__terms_ZQSY0:hover{color:var(--BrandHighlight)}}html.app__layout--desktop .referral-program-reg_guGCi{padding:12px 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-reg": `referral-program-reg_guGCi`,
	"referral-program-reg__terms": `referral-program-reg__terms_ZQSY0`
};
export default ___CSS_LOADER_EXPORT___;
