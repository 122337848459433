// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-popup-header_WrWhb{display:flex;justify-content:space-between;height:48px}.referral-program-popup-header_pointer_fwxVs{cursor:pointer}.referral-program-popup-header__left_u45EK{display:inline-flex;column-gap:16px;align-items:center;padding:8px 0 8px 16px}.referral-program-popup-header__left-content_Ywz3u{display:inline-flex;flex-direction:column}.referral-program-popup-header__right_QZuSS{display:flex;overflow:hidden}.referral-program-popup-header__title_X2BGp{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;margin:0;color:var(--DAlertButtonText)}.referral-program-popup-header__arrow_r043V{display:flex;flex-shrink:0;align-items:center;justify-content:center;width:44px;height:48px;color:var(--DAlertButtonText);cursor:pointer;transition:.4s ease-out;transform:rotate(0deg)}.referral-program-popup-header__steps_yhONY{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;margin:0;color:var(--DAlertButtonText)}.referral-program-popup-header__banner_fSsxB{height:48px}.referral-program-popup-header__banner_fSsxB img{height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-popup-header": `referral-program-popup-header_WrWhb`,
	"referral-program-popup-header_pointer": `referral-program-popup-header_pointer_fwxVs`,
	"referral-program-popup-header__left": `referral-program-popup-header__left_u45EK`,
	"referral-program-popup-header__left-content": `referral-program-popup-header__left-content_Ywz3u`,
	"referral-program-popup-header__right": `referral-program-popup-header__right_QZuSS`,
	"referral-program-popup-header__title": `referral-program-popup-header__title_X2BGp`,
	"referral-program-popup-header__arrow": `referral-program-popup-header__arrow_r043V`,
	"referral-program-popup-header__steps": `referral-program-popup-header__steps_yhONY`,
	"referral-program-popup-header__banner": `referral-program-popup-header__banner_fSsxB`
};
export default ___CSS_LOADER_EXPORT___;
