// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-reward-item--separator_NMoKy{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal;display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-reward-item--separator": `referral-program-reward-item--separator_NMoKy`
};
export default ___CSS_LOADER_EXPORT___;
