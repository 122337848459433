// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-top-bar_z2LxW{height:60px;position:relative;z-index:1;width:100%}.referral-program-top-bar_z2LxW .referral-program-top-bar__content_RLWo_{height:120px;background-color:var(--Highlight)}.referral-program-top-bar_z2LxW .referral-program-top-bar__left_TW0dD{width:119px;padding:20px 0}.referral-program-top-bar_z2LxW .referral-program-top-bar__title_n8gTK{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}.referral-program-top-bar_z2LxW .referral-program-top-bar__logo_NOc4D{width:96px}.referral-program-top-bar__content_RLWo_{display:flex;justify-content:space-between;width:100%;padding:0 0 0 44px;overflow:hidden}html.app__layout--desktop .referral-program-top-bar__content_RLWo_{padding:0 44px 0 32px}.referral-program-top-bar__left_TW0dD{z-index:1;display:inline-flex;flex-direction:column}.referral-program-top-bar__logo_NOc4D{width:auto;height:auto;margin-top:16px}html.app__layout--desktop .referral-program-top-bar__logo_NOc4D{width:auto;margin-top:16px}.referral-program-top-bar__close_suPI8{top:0;left:0;padding-top:8px;position:absolute}html.app__layout--desktop .referral-program-top-bar__close_suPI8{right:0;left:auto}.referral-program-top-bar__close-button_yiznh{color:var(--TextSecondary)}@media(hover:hover)and (pointer:fine){.referral-program-top-bar__close-button_yiznh:hover:not([disabled]){color:var(--TextDefault);background-color:#0000}}.referral-program-top-bar__close-button_yiznh:focus-visible{color:var(--TextDefault);background-color:#0000;border:1px solid #ffffff4d}.referral-program-top-bar__right_SKQ1F{padding-right:16px}html.app__layout--desktop .referral-program-top-bar__right_SKQ1F{padding-right:0}.referral-program-top-bar__image_eWAu1{display:block;padding-right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-top-bar": `referral-program-top-bar_z2LxW`,
	"referral-program-top-bar__content": `referral-program-top-bar__content_RLWo_`,
	"referral-program-top-bar__left": `referral-program-top-bar__left_TW0dD`,
	"referral-program-top-bar__title": `referral-program-top-bar__title_n8gTK`,
	"referral-program-top-bar__logo": `referral-program-top-bar__logo_NOc4D`,
	"referral-program-top-bar__close": `referral-program-top-bar__close_suPI8`,
	"referral-program-top-bar__close-button": `referral-program-top-bar__close-button_yiznh`,
	"referral-program-top-bar__right": `referral-program-top-bar__right_SKQ1F`,
	"referral-program-top-bar__image": `referral-program-top-bar__image_eWAu1`
};
export default ___CSS_LOADER_EXPORT___;
